import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/partners';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MobileFeatures from 'components/content-blocks/mobile-features';
import ProductVideo from 'components/content-blocks/product-video';
import Reviews from 'components/content-blocks/reviews';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';

import 'templates/styles/partner.scss';

export default function PartnerTemplate({ pageContext }) {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "partner-parent-page" } }) {
        edges {
          node {
            wordpress_id
            acf {
              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
            }
          }
        }
      }
      reviews: allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            acf {
              reviews_section {
                title
                sub_title
                copy
              }
            }
          }
        }
      }
    }
  `);

  const slug = pageContext.slug;
  const pageData = data.allWordpressPage.edges[0].node.acf;
  const yoast = pageContext.yoast_head_json;
  const hero = pageContext.acf.hero;
  const cards1 = pageContext.acf.cards_1;
  const productVideo = pageData.video_block_1;
  const customerCard = pageData.customer_feature_benefit;
  const featuresGridMobile = pageData.features_grid_mobile_1;
  const customerQuote = pageData.customer_quote_1;
  const featuresGrid = pageContext.acf.features_grid;
  const reviews = data.reviews.edges[0].node.acf.reviews_section;
  const legalDisclaimer = pageContext.acf?.basic_title_subtitle_copy_image?.copy;

  return (
    <Layout legalText={ hero.signup.legal_text }>
      <Yoast { ...yoast } />
      <div className="partner-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroFeatures
          { ...hero }
          uniqueId={ `${slug}Hero` }
          data-section={ `${slug}Hero` }
          signupModalLaunch={ hero.signup.launch_modal_button_text }
        />
        <HighlightCards
          { ...cards1 }
          className="background-green"
          sub_title={ cards1.header.title }
          hasTitle
          hasCopy
          hasImage
        />
        <ProductVideo { ...productVideo } />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <MobileFeatures { ...featuresGridMobile } />
        <CustomerFeatureBenefit { ...customerCard[1] } className="reverse" />
        <FeaturesGrid { ...featuresGrid[0] } />
        <QuoteBox { ...customerQuote } />
        <FeaturesGrid { ...featuresGrid[1] } className="reverse" />
        <Reviews { ...reviews } />
        { legalDisclaimer && <section className="legal-disclaimer-section">
          <p className="copy">{ legalDisclaimer }</p>
        </section> }
      </div>
    </Layout>
  );
}