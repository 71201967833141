import { graphql, useStaticQuery } from 'gatsby';

import Link from 'components/link/link';

import './styles/partners.scss';
import Signups from '../modal/signups';

type NavigationPartnersProps = {
  trialLength?: number
}

const NavigationPartners = ({ trialLength }:NavigationPartnersProps) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              nav_logo {
                source_url
                alt_text
              }
            }
          }
        }
      }
    }
  `);

  return (
    <nav className="navigation-partners" data-section="landingPageNavigation">
      <div className="container">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-one-quarter-desktop is-one-half-mobile">
            <Link className="logo-link" to="/" title="When I Work - Home">
              <img
                className="logo"
                src={
                  data.allWordpressAcfOptions.edges[0].node.options.nav_logo
                    .source_url
                }
                alt={
                  data.allWordpressAcfOptions.edges[0].node.options.nav_logo
                    .alt_text || 'When I Work Logo - To Home Page'
                }
              />
            </Link>
          </div>
          <div className="column is-three-quarters-desktop is-one-half-mobile has-text-right buttons nav-items">
            <Signups
              uniqueId="landingNavigation"
              longPlaceholder
              showModalButton
              fieldLayout="row"
              signupModalLaunch="Start Free Trial"
              signupModalSubmit="Start Free Trial"
              buttonClasses="button is-signup-button is-rounded"
              trialLength={ trialLength }
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationPartners;
