import { ReactNode, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';

import Footer from 'components/footer/default';
import Head from 'components/globals/head';
import NavigationPartners from 'components/navigation/partners';

ReactModal.setAppElement('#___gatsby');

type LayoutPartnersProps = {
  children?: ReactNode
  legalText?: string,
  showInitFields?: boolean,
  signupModalSubmit?: string
  trialLength?: number
} 

const Layout = ({ 
  children,
  showInitFields,
  signupModalSubmit,
  legalText,
  trialLength,
}:LayoutPartnersProps) => {
  
  const [showFields, setShowFields] = useState(true);

  useEffect(() => {
    if (showInitFields === false) {
      setShowFields(false);
    } else {
      setShowFields(true);
    }
  }, [showInitFields]);

  return (
    <div className="layout-wrapper">
      <Head />
      <ModalProvider>
        <NavigationPartners trialLength={ trialLength } />
        <div role="main">{children}</div>
        <Footer
          showInitFields={ showFields }
          signupModalSubmit={ signupModalSubmit }
          legalText={ legalText }
          trialLength={ trialLength }
        />
      </ModalProvider>
    </div>
  );
};

export default Layout;
